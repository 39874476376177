<template>
  <div>
    <div class="font-bold text-xl mb-10">Add multiple</div>

    <template>
      <div key="admin">
        <template v-if="getFormError(form)">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon">!</span>
            <span>{{ getFormError(form) }}</span>
          </div>
        </template>

        <form-group
          :form="form"
          name="employer"
          v-model="form.data.employer.value"
        >
          Enter Compny Name
        </form-group>

        <div class="flex flex-row justify-between">
          <div class="text-sm mb-4">Upload Excel or CSV File</div>
          <a href="#!" class="underline text-xs mb-4" @click.prevent="help">
            Need help?
          </a>
        </div>

        <dropzone
          accept=".xlsv, .xlsx, .csv"
          v-model="form.data.sheet.value"
          :uploading="uploading"
          :uploaded="uploaded"
        ></dropzone>

        <button
          v-if="bulkFieldErrors"
          class="btn btn-orange mb-5"
          @click="() => (showBulkFieldErrors = !showBulkFieldErrors)"
        >
          {{ showBulkFieldErrors ? 'Hide' : 'Show' }} Errors
        </button>

        <template v-if="bulkFieldErrors && showBulkFieldErrors">
          <div class="text-red-500 text-sm mb-5">
            Please fix the following errors and try again.
          </div>

          <div class="border border-blue-200 rounded text-xs mb-10 break-all">
            <template v-for="(row, n) in bulkFieldErrors">
              <div :key="n">
                <div :class="{ 'border-t border-blue-200': n != 0 }">
                  <div class="border-b border-blue-200 px-3 py-2">
                    Row: {{ row.index }}
                  </div>
                  <div class="grid grid-cols-4">
                    <div class="col-span-1 px-1 py-1">
                      <div class="mb-2">{{ row.data.first_name }}</div>
                      <div v-if="row.errors.first_name" class="text-red-500">
                        {{ row.errors.first_name[0] }}
                      </div>
                    </div>
                    <div class="col-span-1 px-1 py-1 border-l border-blue-200">
                      <div class="mb-2">{{ row.data.last_name }}</div>
                      <div v-if="row.errors.last_name" class="text-red-500">
                        {{ row.errors.last_name[0] }}
                      </div>
                    </div>
                    <div class="col-span-1 px-1 py-1 border-l border-blue-200">
                      <div class="mb-2">{{ row.data.email }}</div>
                      <div v-if="row.errors.email" class="text-red-500">
                        {{ row.errors.email[0] }}
                      </div>
                    </div>
                    <div class="col-span-1 px-1 py-1 border-l border-blue-200">
                      <div class="mb-2">{{ row.data.phone_no }}</div>
                      <div v-if="row.errors.phone_no" class="text-red-500">
                        {{ row.errors.phone_no[0] }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </template>

        <div class="text-right">
          <button
            type="button"
            class="btn btn-blue"
            :disabled="!sheetRows || !sheetRows.length"
            @click.prevent="showPreview"
          >
            Preview
          </button>
        </div>
      </div>
    </template>

    <modal
      className="w-full md:w-1/2 lg:w-8/10 xl:w-5/10"
      ref="confirmationModal"
    >
      <template v-if="sheetRows">
        <div class="text-lg font-bold mb-4">Review users to upload.</div>

        <div class="mb-10">
          <table class="table table-fixed w-full">
            <thead>
              <tr>
                <th class="w-1/5">First Name</th>
                <th class="w-1/5">Last Name</th>
                <th class="w-1/5">Email</th>
                <th class="w-1/5">Phone</th>
                <!-- <th class="w-1/5">Role</th> -->
                <!-- <th class="w-1/5">Department</th> -->
              </tr>
            </thead>
          </table>
          <div class="max-h-500px overflow-y-auto">
            <table class="table table-fixed w-full">
              <tbody>
                <template v-for="(row, n) in sheetRows">
                  <tr :key="n">
                    <td class="w-1/5 text-overflow-ellipse">
                      <template v-if="row.first_name">
                        {{ row.first_name }}
                      </template>
                      <template v-else>
                        <div class="alert-sm">
                          <em class="alert-sm-icon">!</em>
                          <span>Not provided</span>
                        </div>
                      </template>
                    </td>
                    <td class="w-1/5 text-overflow-ellipse">
                      <template v-if="row.last_name">
                        {{ row.last_name }}
                      </template>
                      <template v-else>
                        <div class="alert-sm">
                          <em class="alert-sm-icon">!</em>
                          <span>Not provided</span>
                        </div>
                      </template>
                    </td>
                    <td class="w-1/5 text-overflow-ellipse">
                      <template v-if="row.email">
                        {{ row.email }}
                      </template>
                      <template v-else>
                        <div class="alert-sm">
                          <em class="alert-sm-icon">!</em>
                          <span>Not provided</span>
                        </div>
                      </template>
                    </td>
                    <td class="w-1/5 text-overflow-ellipse">
                      <template v-if="row.phone_no">
                        {{ row.phone_no }}
                      </template>
                      <template v-else>
                        <div class="alert-sm">
                          <em class="alert-sm-icon">!</em>
                          <span>Not provided</span>
                        </div>
                      </template>
                    </td>
                    <!-- <td class="w-1/5">
											<custom-select
												:options="roles"
												class="select px-4 py-3 border border-blue-200 rounded relative"
												v-model="row.role"
											/>
										</td>
										<td class="w-1/5">
											<custom-select
												:options="groupOptions"
												class="select px-4 py-3 border border-blue-200 rounded relative"
												v-model="row.group"
											/>
										</td> -->
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="alert alert-orange items-start mb-5">
          <i class="alert-icon">
            <ion-icon name="alert" />
          </i>
          <span class="flex flex-col">
            <span class="font-bold">For Correct Results.</span>
            <span>
              If the data doesn't look right, you might have to reformat and
              reupload the document.
            </span>
          </span>
        </div>
        <div class="alert alert-orange items-start mb-5">
          <i class="alert-icon">
            <ion-icon name="alert" />
          </i>
          <span class="flex flex-col">
            <span class="font-bold">For better performance</span>
            <span>
              If there are more than 50 users on the sheet, separate them into
              smaller sheets and upload the sheets one after the other.
            </span>
          </span>
        </div>
        <div class="text-right">
          <button
            class="btn btn-red mr-4"
            type="button"
            @click="closePreview"
            :disabled="form.loading"
          >
            Cancel
          </button>

          <button
            class="btn btn-blue"
            type="button"
            @click.prevent="submit"
            :disabled="form.loading"
          >
            <span v-if="form.loading">Uploading</span>
            <span v-else>Upload Data</span>
          </button>
        </div>
      </template>
      <template v-else>
        <div class="text-sm text-gray-700">No Data To Preview</div>
      </template>
    </modal>

    <modal className="w-full md:w-2/5 xl:w-3/10 px-10 py-10" ref="tipModal">
      <div class="text-lg font-bold mb-4">The file format you need</div>

      <div class="mb-4 text-sm text-gray-500">
        Shown below is the compatible format for adding bulk employees.
      </div>

      <img :src="sheetPreview" alt="Preview" class="w-full mb-8" />

      <div class="flex flex-row">
        <div
          class="w-5px h-5px bg-blue-500 mr-2 mt-5px"
          style="border-radius: 50%; flex: 0 0 5px"
        ></div>
        <div class="text-xs text-gray-500 font-hairline">
          When done, you would have to save the file as an Excel File (.xls /
          .xlsx) or as a CSV File (.csv).
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import XLSX from "xlsx";

export default {
  data() {
    return {
      form: this.$options.basicForm([
        { name: 'sheet', value: null },
        { name: 'employer' },
      ]),
      name: '',
      preview: false,
      roles: [
        { title: 'Super Admin', value: 'company_super' },
        { title: 'HR', value: 'company_hr' },
        { title: 'Finance Manager', value: 'company_finance' },
        { title: 'Audit', value: 'company_audit' },
        { title: 'Department Head', value: 'company_department_head' },
        { title: 'Unit Head', value: 'company_unit_head' },
        { title: 'Employee', value: 'company_staff' },
      ],
      sheetPreview: require('@/assets/sheet-preview-2.png'),
      sheetRows: null,
      showBulkFieldErrors: false,
      uploading: false,
      uploaded: false,
    };
  },
  computed: {
    groups() {
      return this.resources.groups;
    },

    bulkFieldErrors() {
      const errors = this.form.error?.response?.data?.errors;
      var bulkFieldErrors = null;

      if (
        errors &&
        Object.keys(errors).length &&
        this.form.error.response.status === 422
      ) {
        bulkFieldErrors = {};

        for (let key in errors) {
          let match = key.match(/users.(\d+).(\w+)/);
          console.log(match);
          let index = match?.[1];
          let name = match?.[2];
          let error = errors[key];

          // bulkFieldErrors.push({ data: this.sheetRows[index], column: name, error  });
          if (bulkFieldErrors[index]) {
            bulkFieldErrors[index].errors[name] = error;
          } else {
            bulkFieldErrors[index] = {
              index,
              data: this.sheetRows[index],
              errors: {
                [name]: error,
              },
            };
          }
        }
      }

      return bulkFieldErrors;
    },
    fileExtension() {
      const sheet = this.form.data.sheet.value;
      if (!sheet) {
        return null;
      }

      const extensions = {
        csv: [/.csv$/i],
        excel: [/.xls$/i],
      };

      for (let extension in extensions) {
        if (
          extensions[extension].find((expression) =>
            sheet.name.match(expression)
          )
        ) {
          return extension;
        }
      }

      return null;
    },
    groupOptions() {
      return this.groups?.data.map((group) => ({
        value: group.id,
        title: group.name,
      }));
    },
  },
  beforeMount() {
    // this.getGroups();
  },
  watch: {
    'form.data.sheet.value'(data) {
      if (!data) {
        this.sheetRows = null;
        return null;
      }

      const Reader = new FileReader();

      Reader.onload = (event) => {
        const workBook = XLSX.read(event.target.result, { type: 'binary' });

        const sheet = workBook.Sheets[workBook.SheetNames[0]];
        const rows = XLSX.utils.sheet_to_json(sheet);

        this.sheetRows = rows.map((row) => {
          const data = {};

          data.first_name = row[Object.keys(row)[0]];
          data.last_name = row[Object.keys(row)[1]];
          data.email = row[Object.keys(row)[2]];
          data.phone_no = row[Object.keys(row)[3]]?.toString();
          data.phone_no =
            data.phone_no?.length === 10 ? `0${data.phone_no}` : data.phone_no;
          // data.role = 'company_staff';
          // data.group = null;

          // for (let column in row) {
          // 	let columnName = column.toLowerCase().replace(/\s/, '_')
          // 	data[columnName] = row[column];
          // }

          return data;
        });
      };

      Reader.readAsBinaryString(data);
    },
    'employeeForm.data.is_admin.value'(is_admin) {
      if (is_admin) {
        this.employeeForm.data.role.value = '';
      } else {
        this.employeeForm.data.role.value = 'company_staff';
      }
    },
  },
  methods: {
    back() {
      this.preview = false;
    },
    help() {
      this.$refs.tipModal.open();
    },
    closePreview() {
      this.$refs.confirmationModal.close();
    },
    showPreview() {
      if (!this.validateForm(this.form)) return false;
      this.$refs.confirmationModal.open();
    },
    async getGroups() {
      this.loadResource('groups');
    },
    async submit() {
      this.preview = false;

      this.form.loading = true;
      this.form.error = null;
      this.uploading = true;

      await this.sendRequest('admin.users.createMany', {
        data: {
          users: this.sheetRows,
          employer: this.getFormValue('employer'),
        },
        success: () => {
          this.uploaded = true;
          this.$success({
            title: 'Operation Successful!',
            body: 'All users created.',
            button: 'Okay',
          });
          this.closePreview();
        },
        error: (error) => {
          this.form.error = error;
          this.closePreview();
        },
        axiosProps: {
          onUploadProgress: (event) => {
            console.log(event);
          },
          onDownloadProgress: (event) => {
            console.log(event);
          },
        },
      });

      this.form.loading = false;
      this.uploading = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.alert-sm
	@apply bg-red-100 flex-row items-center px-3 rounded-full
	@apply text-red-500 text-xs flex py-2
	&-icon
		@apply bg-red-500 flex flex-shrink-0 h-20px items-center justify-center mr-2
		@apply rounded-full text-center text-white text-xs w-20px
</style>
