<template>
  <div>
    <form @submit.prevent="submit" key="company">
      <template v-if="getFormError(form)">
        <div class="alert alert-red-soft mb-10">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(form) }}</span>
        </div>
      </template>

      <div class="mb-5">
        <div class="font-medium text-xl">Basic Information</div>
        <!-- <div class="text-orange-600 text-xs">* BVN Optional</div> -->
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-10">
        <div class="col-span-1">
          <form-group
            class="mb-0"
            leftIcon="person-outline"
            name="first_name"
            :form="form"
            v-model="form.data.first_name.value"
          >
            First Name <span class="text-orange-600">*</span>
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            class="mb-0"
            leftIcon="person-outline"
            name="last_name"
            :form="form"
            v-model="form.data.last_name.value"
          >
            Last Name <span class="text-orange-600">*</span>
          </form-group>
        </div>
        <div class="col-span-1 sm:col-span-2">
          <form-group
            class="mb-0"
            type="email"
            leftIcon="mail-outline"
            name="email"
            :form="form"
            v-model="form.data.email.value"
          >
            Email Address <span class="text-orange-600">*</span>
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            class="mb-0"
            type="phone_no"
            leftIcon="call-outline"
            name="phone_no"
            :form="form"
            v-model="form.data.phone_no.value"
          >
            Phone Number <span class="text-orange-600">*</span>
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            class="mb-0"
            leftIcon="lock-closed-outline"
            name="bvn"
            :form="form"
            v-model="form.data.bvn.value"
          >
            BVN
          </form-group>
        </div>
      </div>

      <div class="text-right">
        <button type="submit" class="btn btn-blue" :disabled="form.loading">
          <sm-loader class="sm-loader-white" v-if="form.loading" />
          <span v-else>Add User</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: this.$options.basicForm([
        { name: 'first_name', rules: 'required|alpha' },
        { name: 'last_name', rules: 'required|alpha' },
        { name: 'email', rules: 'required|email' },
        { name: 'phone_no', rules: 'required|phone' },
        { name: 'bvn', rules: 'nullable' },
      ]),
    };
  },
  methods: {
    async submit() {
      if (!this.validateForm(this.form)) return false;

      this.form.error = false;
      this.form.loading = true;

      await this.sendRequest('admin.users.new', {
        data: {
          ...this.getFormData(this.form),
        },
        success: (response) => {
          const { user } = response.data;

          this.$success({
            title: 'Operation Successful',
            body: 'User Created Successfully',
            button: {
              text: 'Complete Onboarding',
              onClick: () => {
                this.$router.push({
                  name: 'users-onboarding',
                  query: { userId: user.id }
                })
              },
            },
          });
          this.form = this.resetForm(this.form);
        },
        error: (error) => {
          this.form.error = error;
          this.mapFormErrors(this.form, error?.response?.data?.errors);
        },
      });

      this.form.loading = false;
    },
  },
};
</script>
